import React from "react";
import {useStaticQuery, graphql} from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from 'gbimage-bridge';


const StaticBackgroundImage = ({ image, hasDefault, children }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "default-background.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, breakpoints: [1024, 1280, 1440, 2500])
          }
        }
      }
    `
  );
  const defaultImg =  hasDefault && getImage(placeholderImage);
  const img = getImage(image);

  return (
    <>
      {!(img || defaultImg) && (<>{children}</>)}
      {(img || defaultImg) && (
        <BgImage Tag="div" className="background-image" image={img || defaultImg} style={{height: "100%", backgroundAttachment: "fixed"}}>
          {children}
        </BgImage>
      )}
    </>
  )
}

export default StaticBackgroundImage;